import React, { useEffect, useContext,useState } from 'react';

import '../CoinCSS.css'
import { AppContext } from "../Dataprovider";
import {
    EmailShareButton,
    FacebookShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
  } from "react-share";

  import {
    FacebookShareCount,
  } from "react-share";

  import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
  } from "react-share";

function SocialShare() {

  const { state } = useContext(AppContext);
  const [sharetitle, setShareTitle] = useState("title");
  const [shareUrl, setShareUrl] = useState(window.location);

    useEffect(() => {
        //do something here
    }, []); // <- add a changing area here variable here like milliseconds

  return (
    <div  class="container text-center">
      <div class="row align-items-start">
          <div className="flex-item-border">ip: {state.milliseconds}</div>
          <div className="flex-item-border">network: {state.milliseconds}</div>
          <div className="flex-item-border">version: {state.milliseconds}</div>
      </div>
      <div class="row align-items-center">
        <div  class="col col-sm">
        <EmailShareButton url={window.location} title={sharetitle}  ><EmailIcon size={64} round={true} /></EmailShareButton>

        <FacebookShareButton url={window.location}>
            <FacebookShareCount url={window.location}>
            {shareCount => <span className="myShareCountWrapper">{shareCount}</span>}
            </FacebookShareCount>
            <FacebookIcon size={64} round={true} />
        </FacebookShareButton>


        <RedditShareButton url={window.location}><RedditIcon size={64} round={true} /></RedditShareButton>
        <TelegramShareButton url={window.location}><TelegramIcon size={64} round={true} /></TelegramShareButton>
        <TwitterShareButton  url={window.location} ><TwitterIcon size={64} round={true} /></TwitterShareButton>
        </div>

      </div>
</div>       
    
  );
};

export default SocialShare