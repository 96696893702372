import React, { useEffect, useContext } from 'react';

import '../CoinCSS.css'
import { AppContext } from "../Dataprovider";
import MyPieChart from '../MyPieChart';
import TimeLine from '../TimeLine';

function Row1() {

  const { state } = useContext(AppContext);
  

    useEffect(() => {
        //do something here
    }, []); // <- add a changing area here variable here like milliseconds

  return (
    <div  class="container align-top">
      <div class="row align-items-middle">
          <div  class="col col-sm order-1"><MyPieChart /></div>
          <div  class="col col-sm order-2 align-top"><TimeLine /></div>
      </div>
</div>       
    
  );
};

export default Row1
