import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';
import { debugdiv, useMountEffect } from '../Helpers';


import '../CoinCSS.css'
import { AppContext } from "../Dataprovider";
import axios from "axios";
//import { ExplorerDataContext, explorer_state_context } from './context';
import { wpapi_context } from './context';
import Button from 'react-bootstrap/Button'

function WordpressAPI() {

    const { state } = useContext(AppContext);
    const { axios_state, setAxios_state } = useContext(wpapi_context);
    //const [axios_state, setAxios_state] = useState(axios_state_import);
    // Set state object with values that are changed programatically
    const wpstate = {
    loggedIn: false,
    restUrl: "http://crypto-fan-store.eu/wp-json/",
    user: "apiuser",
    pwd: "apipassword?",
    token: "wp-token",
  };

  const creds = {
    username: 'username',
    password: 'password',
  }



    //const {axios_state}  = useContext(axios_state_def);
    //const { axios_state } = useContext(axios_state_def)
    //const [apidata, setApiData] = useState({});
    const [WordpressData, setWordpressData] = useState(state.VisitorData);
    //let WordpressData = state.WordpressData;

    var API_URL = "https://crypto-fan-store.eu/wp-json/wp/v2/posts";
    //axios.defaults.baseURL='http://crypto-fan-store.eu';

    //const access_token="5tcW MBjd 92Wx BqkF B52P evHY";
    const access_token = sessionStorage.getItem('JWT_token');


    const wp_user = {
        user : {
          first_name: "",
          last_name: "",
          email: "",
          password: "apiuser",
          username: "apipassword?",
          locale: "en_US",
          description: "",
          name: ""
        },
        alertText : null
      };

      let testuser = JSON.stringify({
        "username": "apiuser",
        "password": "apipassword?",
        "issueJWT": true
      });


    const headers = {
      'Content-Type': 'application/json', 
      'Accept': 'application/json'
        }

    // Only run once while after mounting
    useMountEffect(()=>{               
        //fetchAPI();
    });
    // https://jsonplaceholder.typicode.com/users
    //setting the state variable
    const fetchAPI = async () => {
   

    // Make request to authenticate
    await axios({
      method: "post",
      url: 'https://crypto-fan-store.eu/wp-json/aam/v2/authenticate',
      headers: headers,
      data: wp_user

    }) // 2
      .then(response => {
        console.log(JSON.stringify(response.data));
        //axios_state.response = response.status;
        //axios_state.statusText = response.statusText;
        if (200 === response.status) {
          localStorage.setItem("token", response.data.token);
       //   Cookies.set(state.token, response.data.token, {
        //    expires: 1,
        //    secure: true
        //  }); // 3
          //init(); // 4
        } else {
          // Executed when response code is not 200
          alert("Login failed, please check credentials and try again!");
        }
      }) // 5
      .catch(error => {
        console.log(error.response.status, error.response.statusText);
        console.log(error.message , error.code);
        //axios_state.response.status = error.response.status;
        //axios_state.response.statusText = error.code;
        // Also log the actual error
        //console.error(error);
      }); // 6
       
        //setApiData(response.data);
       //console.log(apidata);
        };
        
        const fetchJWT = async () => {
          let data = JSON.stringify({
            "username": "apiuser",
            "password": "apipassword?",
            "issueJWT": true
          });
          
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://crypto-fan-store.eu/wp-json/aam/v2/authenticate',
            headers: { 
              'Content-Type': 'application/json', 
              'Accept': 'application/json'
            },
            data : data
          };


          
          axios.request(config)
          .then((response) => {
            console.log(response.data);
            //console.log(JSON.stringify(response.data));
            if (200 === response.status) {
              console.log(response);
              console.log('fetchJWT',response.status, "OK", response.data.jwt.token_expires);
              sessionStorage.setItem("token", response.data.jwt.token);
              sessionStorage.setItem("token_expires", response.data.jwt.token_expires);
              //   Cookies.set(state.token, response.data.token, {
               //    expires: 1,
               //    secure: true
               //  }); // 3
                 //init(); // 4
               } else {
                 // Executed when response code is not 200
                 alert("Login failed, please check credentials and try again!");
               }

          })
          .catch((error) => {
            console.log(error);
          });
   
              }; 
              
        const TimeStampToLocale = (timestamp) => {
          //const timestamp = Math.floor(Date.now()/1000);
          const locale="nl-be";
          
          const new_timestamp = new Date(timestamp * 1000);
          
      
          //const formatedDate = new Date(timestamp * 1000).toLocaleString({locale});
      
          //console.log(new_timestamp);
          //console.log(new_timestamp.toLocaleString("nl-be"));
          //console.log(locale);
          //onsole.log(new_timestamp.toLocaleString({locale}));
          return new_timestamp.toLocaleString("nl-be");
      
          //console.log(formatedDate);
      
        };

      const checkTokenexpiry = () => {

        const access_token = sessionStorage.getItem('token');
        const token_expires = sessionStorage.getItem('token_expires');

        const js_timestamp = new Date().getTime();
        const timestamp = (js_timestamp /1000).toFixed(0);

        console.log('timestamp',timestamp, TimeStampToLocale(timestamp));
        console.log('token',token_expires, TimeStampToLocale(token_expires));

        //fetchtoken when expired or null
        if(token_expires <=  timestamp || token_expires === null ){
          fetchJWT();
        } else {
          console.log('token is still valid');
        }  
   
        };



    return (
            <div class="container text-center">
                <div class="row align-items-start">

                    <div class="col col-sm order-3" >version: </div>

                </div>
                <div class="row align-items-start">
                    <div class="col col-sm" >region: {state.milliseconds}</div>
                    <div class="col col-sm" >region_code: {state.milliseconds}</div>
                    <div class="col col-sm" >country: {state.milliseconds}</div>
                    <div class="row"><Button variant="primary" size="lg" onClick={fetchJWT}>Fetch Token</Button></div> 
                    <div class="row"><Button variant="primary" size="lg" onClick={checkTokenexpiry}>Check expiry</Button></div> 
                </div>
            </div>        
      );
    };

    export default WordpressAPI;