import React, { useRef, useState, useEffect, useCallback, useContext, useReducer } from 'react';
import { debugdiv, useMountEffect } from '../Helpers';


import '../CoinCSS.css';
import { AppContext } from "../Dataprovider";
import axios from "axios";
import { ExplorerDataContext, explorer_state_context } from './context';
import sortjsonarray from '../sort-json-array'; 
import Button from 'react-bootstrap/Button';
import cg_context from '../Test_CG/context';
import { notEqual } from 'assert';






function ApiExplorer() {

  const { state } = useContext(AppContext);
  //const  test  = useContext(explorer_state_context);

  const [ CoinAdress, setCoinadress] = useState( state.CoinAdress);

  const EXPLORER_API_ROUTES = {
    PING: "/ping",
    GETBLOCKCOUNT: "/api/getblockcount",
    GETBALANCE:"/ext/getaddresstxs/" + CoinAdress + "/0/50"
  };

  

  




  // set Adress from Path






  const [ExplorerData, setExplorerData] = useState(state.ExplorerData);
  const [CoinGeckoData, setCoinGeckoData] = useState(state.CoinGeckoData);
  
  const [coinData, setcoinData] = useState(state.CoinGeckoData.coin[0]);
  const explorerRefreshTime = useState( process.env.REACT_APP_COIN_EXPLORER_REFRESH)
  
  //const [ExplorerData, setExplorerData] = useState(state.ExplorerData);
  //const [apidata, setApiData] = useState({}); 

  // connect visitor data to locale
  const [ VisitorFiatCurrency, setVisitorFiatCurrency]  =useState("USD".toLocaleLowerCase());
  const [ VisitorCryptoCurrency, setVisitorCryptoCurrency]  =useState("BTC".toLocaleLowerCase());
  
  // connect coingecko data to local variables
  const [CoinSymbol]    = useState(coinData["symbol"].toUpperCase());
  const [CurrentPriceArray]  = useState(coinData["market_data"]);
  const [CurrentFiatPrice]   = useState( CurrentPriceArray["current_price"][VisitorFiatCurrency]);
  const [CurrentCryptoPrice]   = useState( CurrentPriceArray["current_price"][VisitorCryptoCurrency]);
  
  // total balance in pandacoin / mintcoin
  const [totalbalance, setTotalbalance] = useState(state.AddressBalance);
  const [totalFiatbalance, setTotalFiatbalance] = useState(0);
  const [totalCryptobalance, setTotalCryptobalance] = useState(0);

    
  
  const [debugvisible, setDebugvisible] = useState(true);
    const [seconds, setSeconds] = useState(0);
    const [posts, setPosts] = useState([]);
    const [results, setResults] = useState([]);
    const [data, setData] = useState( {} );
    const [apidata, setApiData] = useState();
    const [resultsstring, setResultsstring] = useState([]);
    const refDebugDiv2 = useRef();
    const [command,setCommand] = useState();  

    var API_URL = ""; 

    //setting the state variable
    const fetchAPI = async () => {
     setCommand(API_URL);
    console.log(API_URL);
     const response = await axios(API_URL);
     console.log(response);
     console.log(response.data);
     if(response.data ==='There was an error. Check your console.'){console.log('Check the daemon as the fault is originating from there');}
     
      //setApiData(response.data);
      setApiData(response.data)
      console.log(apidata);
      };  

      //setting the state variable
      const ce_ping = () => {
      API_URL = process.env.REACT_APP_COIN_EXPLORER + EXPLORER_API_ROUTES.GETBLOCKCOUNT;
      console.log(API_URL);
      //console.log(context.Coin_Market);
      fetchAPI();

    }; 

    useEffect(() => {
      ce_ping();

    }, [ ]);
    
    const explorer_getbalance = () => {
      API_URL = process.env.REACT_APP_COIN_EXPLORER + EXPLORER_API_ROUTES.GETBALANCE;
      fetchAPI();

      console.log(API_URL);  
    };

    const explorer_markets = () => {
      //  https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=mintcoin&order=market_cap_desc&per_page=100&page=1&sparkline=false
      API_URL = process.env.REACT_APP_COIN_EXPLORER + EXPLORER_API_ROUTES.COIN_MARKET_DETAILS;
      //API_URL = API_V3_URL+'/coins/markets?vs_currency='+vs_currency+'&ids='+id+'&order=market_cap_desc&per_page=100&page=1&sparkline=false';
      //fetchResponse();
      console.log(API_URL);  
    };
  
  
    useEffect(() => {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 60000);
      return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        //do something here
        ce_ping();
    }, [seconds]); // <- add a changing area here variable here like seconds

    useEffect(() => {
      //do something here
      setResultsstring(JSON.stringify(data));
      console.log('new data has arrived');
      console.log(API_URL);

      //Blockcount was requested
      if(command == process.env.REACT_APP_COIN_EXPLORER + EXPLORER_API_ROUTES.GETBLOCKCOUNT){
        console.log('blockcount was requested');
        console.log(apidata);
        ExplorerData.blockcount=JSON.stringify(apidata);
      }

      //Balance was requested
      if(command == process.env.REACT_APP_COIN_EXPLORER + EXPLORER_API_ROUTES.GETBALANCE){
        console.log('Balance was requested');
        console.log(apidata);
        //test.addresstxs.start=2;
        //test.addresstxs.transactions=apidata;
        ExplorerData.addresstxs.transactions=apidata;
        // calculates from here

      } 

      //DataContext.explorercontext=data;
  }, [apidata]); // <- add a changing area here variable here like seconds


  useEffect(() => {
    //do something here
    console.log('Api_explorer: Coindata has changed');
    process_data();
    //DataContext.explorercontext=data;
}, [coinData]); // <- add a changing area here variable here like seconds

useEffect(() => {
  //do something here
  console.log('Api_explorer: addresstxs has changed');
  process_data();
  //DataContext.explorercontext=data;
}, [ExplorerData.addresstxs.transactions]); // <- add a changing area here variable here like seconds

const process_data = () => {
  //trigger functions when new data has arrived
  console.log('Api_explorer: process_data()');
  createTimeline();
  calculate_fiat_balance();
};




  const TimeStampToLocale = (timestamp) => {
    //const timestamp = Math.floor(Date.now()/1000);
    const locale="nl-be";
    
    const new_timestamp = new Date(timestamp * 1000);
   

    //const formatedDate = new Date(timestamp * 1000).toLocaleString({locale});

    //console.log(new_timestamp);
    //console.log(new_timestamp.toLocaleString("nl-be"));
    //console.log(locale);
    //onsole.log(new_timestamp.toLocaleString({locale}));
    return new_timestamp.toLocaleString("nl-be");

    //console.log(formatedDate);

  };

  const calculate_fiat_balance = () => {
    // so that we get some influence on the number format
    // First create the Timeline to get the pandacoin balance

    var local_fiat = totalbalance * CurrentFiatPrice;
  
    if (local_fiat < 1) {
      var FiatDigits = 4;   
    } else {
      var FiatDigits = 5;
    }

    setTotalFiatbalance(local_fiat.toFixed(FiatDigits));

    
    
    var local_crypto = totalbalance * CurrentCryptoPrice;

    if (local_crypto < 1) {
      var CryptoDigits = 12;   
    } else {
      var CryptoDigits = 4;
    }

    setTotalCryptobalance(local_crypto.toFixed(CryptoDigits));
    setTotalbalance(totalbalance);
    
  
  


  };

  


  const createTimeline = () => {

    let transaction={};
    let transactionlist=[]; 
    var total_send =0;
    var total_received =0;
    var total_balance =0;
    var unsorted_array =[]; 

    //console.log("Original array");
    unsorted_array =  ExplorerData.addresstxs.transactions;
    //console.log(unsorted_array);
    
    
    //records.sort((a, b) => {
//      return new Date(a.order_date) - new Date(b.order_date); // descending
    //})
    
    //records.sort((a, b) => {
//      return new Date(b.order_date) - new Date(a.order_date); // ascending
    //})

    //var test = unsorted_array.
    ExplorerData.addresstxs.transactions.sort((a, b) => {  return  a['timestamp']  -  b['timestamp']; })// ascending
    



    //var test2 = unsorted_array.sort((a, b) => {  return  a['timestamp']  -  b['timestamp']; })// ascending





   for (transaction of  ExplorerData.addresstxs.transactions){
    // make a list based on timestamp  

    total_balance = total_balance - transaction.received + transaction.sent;
    
   

 
    
    console.log(TimeStampToLocale(transaction.timestamp));
    console.log(transaction);
    console.log(total_balance);
    
   } 
   ExplorerData.addresstxs.total_balance = total_balance;
   setTotalbalance(total_balance);


  };



  
        return (
          <div class="container text-center">
              <div class="row">
                  <div class="col col-sm">ip: {state.milliseconds}</div>
                  <div class="col col-sm">network: {ExplorerData.blockcount}</div>
                  <div class="col col-sm">version: {state.milliseconds}</div>
              </div>
              <div class="row">                  
                  <div class="col col-sm">{ totalbalance } {CoinSymbol}</div>
              </div>
              <div class="row">
                  <div class="col col-sm">{ (totalFiatbalance)} {VisitorFiatCurrency} </div>
                  <div class="col col-sm"> <img width="200" src="https://crypto-fan-store.eu/wp-content/uploads/white-glossy-mug-white-15oz-cutting-board-64143c00947e9.jpg"></img></div>
                  <div class="col col-sm">{totalCryptobalance} { VisitorCryptoCurrency}</div>
              </div>
              <div class="row"><Button variant="primary" size="lg" onClick={explorer_getbalance}>Get balance from explorer </Button></div> 
              <div class="row"><Button variant="primary" size="lg" onClick={createTimeline}>Create Time line & balance</Button></div> 
              <div class="row"><Button variant="primary" size="lg" onClick={calculate_fiat_balance}>Calculate FIAT balance</Button></div>
              <div class="row"><Button variant="primary" size="lg" onClick={TimeStampToLocale}>timestamp 2 locales</Button></div> 
          </div>        
    );
  };
  

export default ApiExplorer;