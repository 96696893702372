import React, { useRef, useState, useEffect } from 'react';
import coins_data from './pandacoin/coins.json';

const BasicCoin = {
    id: 'baby',
    name: 'BabyCoin',
    symbol: 'BABY'
  }


const Coin_Ping_Response = {
    gecko_says: "(V3) To the Moon!"
};
  
    const Coin_Market = {
        id: "mintcoin",
        symbol: "mint",
        name: "Mintcoin",
        image: "https://assets.coingecko.com/coins/images/21/large/MintCoin-128.png?1547033650",
        current_price: 0.00001322,
        market_cap: 0,
        market_cap_rank: null,
        fully_diluted_valuation: null,
        total_volume: 0.02768937,
        high_24h: 0.00001559,
        low_24h: 0.00001316,
        price_change_24h: -0.000001794323400462,
        price_change_percentage_24h: -11.94705,
        market_cap_change_24h: 0,
        market_cap_change_percentage_24h: 0,
        circulating_supply: 0,
        total_supply: null,
        max_supply: null,
        ath: 0.01211094,
        ath_change_percentage: -99.89082,
        ath_date: "2021-02-20T07:15:34.260Z",
        atl: 0.00000186,
        atl_change_percentage: 612.08817,
        atl_date: "2015-01-14T00:00:00.000Z",
        roi: null,
        last_updated: "2023-03-20T10:45:31.883Z"
    }

    export const CoinGeckoDataContext={
      id: "babycoin",
      symbol: "babies",
      name: "BabyCoin",
      coin: coins_data,
      last_updated_coin:"1971-03-20T10:45:31.883Z",
      image: "https://assets.coingecko.com/coins/images/21/large/MintCoin-128.png?1547033650",
      current_price: 0.0,
      market_cap: 0,
      market_cap_rank: null,
      fully_diluted_valuation: null,
      total_volume: 0.0,
      high_24h: 0.0,
      low_24h: 0.0,
      price_change_24h: -0.0,
      price_change_percentage_24h: -0.0,
      market_cap_change_24h: 0,
      market_cap_change_percentage_24h: 0,
      circulating_supply: 0,
      total_supply: null,
      max_supply: null,
      ath: 0.0,
      ath_change_percentage: -0.0,
      ath_date: "1971-02-20T07:15:34.260Z",
      atl: 0.0,
      atl_change_percentage: 0.0,
      atl_date: "1971-01-14T00:00:00.000Z",
      roi: null,
      last_updated: "1971-03-20T10:45:31.883Z",
      last_apicall_requested: "",
      last_apicall_received: "",
      last_apiresponse:{}  ,
      last_apicall_updated: "",
  }

    export const coingecko_state_context = React.createContext({
      BasicCoin : BasicCoin,
      CG_data: CoinGeckoDataContext,
      API_URL : 'sss',
      LAST_API_CALL : '',
      API_CALL_TIMESTAMP:'',
      API_CALL_DURATION:'',
      PingResponse : Coin_Ping_Response,
      Coin_Market : {},
      Coin_Info: {} 
    });

function cg_context(props) {

  const functionname ='cg_context';

  return(
    
         <div><b>{ functionname }</b></div> 

  )
}

export default cg_context;


