

import React, { useContext } from 'react';
import { AppContext } from "../Dataprovider";
import '../CoinCSS.css'

function Header() {

  const { state } = useContext(AppContext);

  return (
    <div class="container text-center">
      <header className='App-Header'>        
        <div id="container">
            <h2>You scanned the item of a {process.env.REACT_APP_COIN} #crypto-millionaire.eu account holder</h2>
        </div>
        <div id="flex-container">
          <div  class="col col-sm"><img className="Coin-logo" src={process.env.REACT_APP_COIN_LOGO} alt="logo" /></div>
          <div  class="col col-sm">
            <h5>Account Number: {state.CoinAdress}</h5>
            </div>
        </div>
      </header>
    </div>
    
  );
};

export default Header
