import React from 'react';

//MMQZu9BwDVqbMqf2b5EbsSxmrstPDAPRkm

const Explorer_Coin = {
    id: 'mint',
    name: 'MintCoin',
    symbol: 'MINT'
  }
  // getaddresstxs (/ext/getaddresstxs/hash/start/length)
  // Returns last [length] transactions for address [hash], starting from offset [start]
  const getaddresstxs = {
    coin_adress:"MMQZu9BwDVqbMqf2b5EbsSxmrstPDAPRkm",
    total_balance:0,
    start:0,
    lenght:100,
    transactions:[{
                  "timestamp": 1391723398,
                  "txid": "59f4d87c56f95be8b3ee1e59fad3b34a3a48bdf1fde8b25e3702c4e9e34efbfa",
                  "sent": 0,
                  "received": 160000000,
                  "balance": 189000000
              },
              {
                  "timestamp": 1391722806,
                  "txid": "8600e30e639b28b103ab3abebb598d40ae6d49e5d9641dd9829d8e786ed675a3",
                  "sent": 0,
                  "received": 1000000,
                  "balance": 349000000
              }]
  }; 

export const ExplorerDataContext={
    blockcount:0,
    addresstxs:getaddresstxs
  
  }
  
  const Coin_Market = {
      id: "mintcoin",
      symbol: "mint",
      name: "Mintcoin",
      image: "https://assets.coingecko.com/coins/images/21/large/MintCoin-128.png?1547033650",
      current_price: 0.00001322,
      market_cap: 0,
      market_cap_rank: null,
      fully_diluted_valuation: null,
      total_volume: 0.02768937,
      high_24h: 0.00001559,
      low_24h: 0.00001316,
      price_change_24h: -0.000001794323400462,
      price_change_percentage_24h: -11.94705,
      market_cap_change_24h: 0,
      market_cap_change_percentage_24h: 0,
      circulating_supply: 0,
      total_supply: null,
      max_supply: null,
      ath: 0.01211094,
      ath_change_percentage: -99.89082,
      ath_date: "2021-02-20T07:15:34.260Z",
      atl: 0.00000186,
      atl_change_percentage: 612.08817,
      atl_date: "2015-01-14T00:00:00.000Z",
      roi: null,
      last_updated: "2023-03-20T10:45:31.883Z"
  };

  export const explorer_state_context = React.createContext({
      BasicCoin : Explorer_Coin,
      Coin_Market: Coin_Market,
      addresstxs : getaddresstxs,
      state:'0',
      API_URL : ''
    });

function explorer_context(props) {

  const functionname ='explorer_context';

  return(

         <div><b>{ functionname }</b></div> 

  )
}

export default explorer_context;


