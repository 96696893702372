import React, { useRef, useState, useEffect, useContext } from 'react';
import { app_state_context, debugdiv, useMountEffect } from '../Helpers';
import { AppContext } from "../Dataprovider";
//import { coingecko_state_context } from './context'; 
import Button from 'react-bootstrap/Button'
import axios from "axios";
import { writeFile } from 'fs';
import coins_data from './pandacoin/coins.json';


export const coingecko_state_context = React.createContext({
  Coin : "babycoin",
  BasicCoin : null,
  API_URL : 'sss',
  LAST_API_CALL : '',
  API_CALL_TIMESTAMP:'',
  API_CALL_DURATION:'',
  Coin_Market : {},
  Coin_Info: {} 
});


const CG_API_ROUTES = {
  PING: "/ping",
  SEARCH: "/search",
  SEARCH_TRENDING: "/search/trending",
  COIN: "/coins/"+ process.env.REACT_APP_COIN_COINGECKO_API_ID,
  COIN_LIST: "/coins/list",
  COIN_MARKET: "/coins/markets",
  COIN_MARKET_DETAILS: "/coins/markets?vs_currency=usd&ids=mintcoin&order=market_cap_desc&per_page=100&page=1&sparkline={false}",
  COIN_TICKERS: "/coins/{id}/tickers",
  COIN_HISTORY: "/coins/{id}/history",
  COIN_MARKET_CHART: "/coins/{id}/market_chart",
  COIN_MARKET_CHART_RANGE: "/coins/{id}/market_chart/range",
  COIN_OHLC: "/coins/{id}/ohlc",
  SIMPLE_PRICE: "/simple/price",
  SIMPLE_SUPPORTED_CURRENCIES: "/simple/supported_vs_currencies",
  SIMPLE_TOKEN_PRICE: "/simple/token_price/{id}",
  CONTRACT: "/coins/{id}/contract/{contract_address}",
  CONTRACT_MARKET_CHART: "/coins/{id}/contract/{contract_address}/market_chart",
  CONTRACT_MARKET_CHART_RANGE: "/coins/{id}/contract/{contract_address}/market_chart/range",
  EXCHANGES: "/exchanges",
  EXCHANGE_LIST: "/exchanges/list",
  EXCHANGE_ID: "/exchanges/{id}",
  EXCHANGE_ID_TICKER: "/exchanges/{id}/tickers",
  EXCHANGE_ID_VOL_CHART: "/exchanges/{id}/volume_chart",
  FINANCE_PLATFORM: "/asset_platforms",
  INDEXES: "/indexes",
  INDEXES_LIST: "/indexes/list",
  INDEXES_MARKET_ID: "/indexes/{market_id}/{id}",
  INDEXES_LIST_MARKET_AND_ID: "/indexes/list_by_market_and_id/{market_id}/{id}",
  DERIVATIVES: "/derivatives",
  DERIVATIVES_EXCHANGES: "/derivatives/exchanges",
  DERIVATIVES_EXCHANGES_ID: "/derivatives/exchanges/{id}",
  DERIVATIVES_EXCHANGES_LIST: "/derivatives/exchanges/list",
  EXCHANGE_RATES: "/exchange_rates",
  GLOBAL: "/global",
  GLOBAL_DEFI: "/global/decentralized_finance_defi",
}

function Test_CG(props) {

  const functionname ='Test_CG';
  const API_V3_URL = "https://api.coingecko.com/api/v3";  
  var API_URL = API_V3_URL; 
  const PRO_API_V3_URL = "https://pro-api.coingecko.com/api/v3";
  const { state } = useContext(AppContext);

  


const writeToJSON = (data) => {

  const DATA_PATH = './pandacoin/coins2.json'

  writeFile(
    DATA_PATH,
    JSON.stringify(data),
    (err) => {
      if (err) return console.error(err)
      console.log('Wrote data to ', DATA_PATH)
    },
  )
}
  
  const [data, setData] = useState( {} );
  const [post, setPost] = React.useState(null);

      // Only run once while after mounting
    useMountEffect(()=>{      
      


    });
  
  const run_once = async () => {

    //state.Coin_Market.id =state.CoinGeckoData.coin["id"];
    state.CoinGeckoData.symbol  =state.CoinGeckoData.last_apiresponse["symbol"];
    //state.Coin_Market.symbol =state.CoinGeckoData.last_apiresponse["name"];
       
  };





    const fetchcoins = async () => {

      var cache_time = 1000*60*15;

      // the input is in ISO-8601 format
      var utcDate = "2022-03-19T20:15:50.000Z";
      var DateCoinDataContext = Date.parse(state.CoinGeckoData.coin[0].last_updated);
      var unixtime = Date.now();
 
      // cache time = milliseconds * seconds * minutes
  
      var localDate = new Date(utcDate).toLocaleString("en-US", {
        localeMatcher: "best fit",
        timeZoneName: "short"
      });
  
      console.log(utcDate);
      console.log(localDate);
      console.log(Date.now());
      console.log(Date.parse(utcDate));
      console.log(Date.now() - Date.parse(utcDate));   

    };

  //setting the state variable
  const fetchResponse = async () => {
        //timestamp first
        state.CoinGeckoData.last_apicall_requested=Date.now();
        const response = await axios(state.CoinGeckoData.last_apicall);
        setData(response);
        state.CoinGeckoData.last_apiresponse=response.data;
        setPost(state.CoinGeckoData.last_apiresponse);
        console.log(state.CoinGeckoData.last_apiresponse);
        //writeToJSON(state.CoinGeckoData);
       

       // setPosts("");
        
        //console.log(state.CoinGeckoData.last_apiresponse);
        //console.log(data);
        //console.log(state.CoinGeckoData.last_apiresponse);
        //console.log(state.CoinGeckoData.last_apiresponse);
        state.CoinGeckoData.last_apicall_received=Date.now();
        state.CoinGeckoData.coin[0]=state.CoinGeckoData.last_apiresponse; 

    
          };

  const baby = async () => {

    // the input is in ISO-8601 format
    var utcDate = "2022-03-19T20:15:50.000Z";
    var utcDate = state.CoinGeckoData.coin[0].last_updated;
    // cache time = milliseconds * seconds * minutes
    var cache_time = 1000*60*15;

    var localDate = new Date(utcDate).toLocaleString("en-US", {
      localeMatcher: "best fit",
      timeZoneName: "short"
    });

    console.log(utcDate);
    console.log(localDate);
    console.log(Date.now());
    console.log(Date.parse(utcDate));
    console.log(Date.now() - Date.parse(utcDate));
    
   
  };


  const baby3 = async () => {
    run_once();
    

    state.Coin_Market.current_price = state.CoinGeckoData.last_apiresponse["market_data"]["current_price"]["usd"];
    
   
  };

  //  COIN: "/coins/babycoin",
  const CgGetCoinInfo = async () => {
    state.CoinGeckoData.last_apicall = API_URL + CG_API_ROUTES.COIN;
    fetchResponse();

    //General info
    //state.CoinGeckoData.id = state.CoinGeckoData.last_apiresponse["id"];
    //state.CoinGeckoData.symbol = state.CoinGeckoData.last_apiresponse["symbol"];
    //state.CoinGeckoData.name = state.CoinGeckoData.last_apiresponse["name"];
    console.log("---");
  
    console.log("---",state.CoinGeckoData.last_apiresponse.market_data);
    console.log("---",state.CoinGeckoData.last_apiresponse["block_time_in_minutes"]);
    //market_data.current_price market_data.current_price.usd market_data.market_cap_rank
    //state.CoinGeckoData.current_price = state.CoinGeckoData.last_apiresponse.market_data.current_price.usd;
    //state.CoinGeckoData.market_cap = state.CoinGeckoData.last_apiresponse["market_cap_rank"];
    //state.CoinGeckoData.market_cap_rank = state.CoinGeckoData.last_apiresponse["market_cap_rank"];


   

  };

 
  
    return (
      <div class="container text-center">
        <div class="row align-items-start">
          <div class="col col-sm order-3">
            <h2>CoinGecko Helpers for {process.env.REACT_APP_COIN_COINGECKO_API_ID}</h2>
          </div>
        </div>
        <div class="row align-items-start">
          <div class="col col-sm" >updated: {state.CoinGeckoData.coin[0].last_updated}</div>
          <div class="col col-sm" >update unixtime:{Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.now())}</div>
          <div class="col col-sm" >needs update: {state.CoinGeckoData.last_apicall_received - state.CoinGeckoData.last_apicall_requested}</div>
        </div>
        <div class="row align-items-start">
          <div class="col col-sm" >timestamp: { Date.now()}</div>
          <div class="col col-sm" >update unixtime: {Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.now())}</div>
          <div class="col col-sm" >needs update: {state.CoinGeckoData.last_apicall_received - state.CoinGeckoData.last_apicall_requested}</div>
        </div>
        <div class="row align-items-start">
          <div class="col col-sm" >last_apicall_requested: {state.CoinGeckoData.last_apicall_requested}</div>
          <div class="col col-sm" >last_apicall_received: {state.CoinGeckoData.last_apicall_received}</div>
          <div class="col col-sm" >last_apicall in millisconds: {state.CoinGeckoData.last_apicall_received - state.CoinGeckoData.last_apicall_requested}</div>
        </div>
        <div class="row align-items-start">
          <div class="col col-sm"><Button variant="primary" size="lg" onClick={baby}>Make 2 Babies</Button></div>
          <div class="col col-sm"><Button variant="primary" size="lg" onClick={CgGetCoinInfo}>Get {process.env.REACT_APP_COIN_COINGECKO_API_ID} Info</Button></div> 
          <div class="col col-sm"><Button variant="primary" size="lg" onClick={baby3}>Make 3 Babies</Button></div> 
        </div>
        <div class="col col-sm">
            <h3>{process.env.REACT_APP_COIN_COINGECKO_API_ID} CoinGecko Info</h3>
          </div>
        <div class="row align-items-start">
          <div class="col col-sm" >id: {}</div>
          <div class="col col-sm" >symbol: {state.CoinGeckoData.symbol}</div>
          <div class="col col-sm" >name: {state.CoinGeckoData.name}</div>
        </div>
        <div class="col col-sm">
            <h3>{process.env.REACT_APP_COIN_COINGECKO_API_ID} CoinGecko Market Cap</h3>
          </div>
        <div class="row align-items-start">
          <div class="col col-sm" >id: {state.CoinGeckoData.current_price}</div>
          <div class="col col-sm" >symbol: {state.CoinGeckoData.market_cap}</div>
          <div class="col col-sm" >name: {state.CoinGeckoData.market_cap_rank}</div>
        </div>
        <div class="row align-items-start">
          <div class="col col-sm " > {state.CoinGeckoData.id} </div>
        </div>
        <div class="row align-items-start">
          <div class="col col-sm " > {JSON.stringify(state.CoinGeckoData.last_apiresponse["id"] )}  </div>
        </div>
        
      </div>
           
        
    );
  };
  

export default Test_CG;