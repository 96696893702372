import React from 'react';

export const default_addresstxs = {
            coin_adress:'Mj3rVsNX6Qa2uAxW3mqKF37idmiEkouq8g',
            start:0,
            lenght:50,
            transactions:[
                {
                    timestamp: 1391723398,
                    txid: '59f4d87c56f95be8b3ee1e59fad3b34a3a48bdf1fde8b25e3702c4e9e34efbfa',
                    sent: 0,
                    received: 160000000,
                    balance: 189000000
                },
                {
                    timestamp: 1391722806,
                    txid: '8600e30e639b28b103ab3abebb598d40ae6d49e5d9641dd9829d8e786ed675a3',
                    sent: 0,
                    received: 1000000,
                    balance: 349000000
                }
            ]
            
        };