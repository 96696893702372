import React, { useState, useEffect, useContext } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import '../CoinCSS.css';

import { AppContext } from "../Dataprovider";
import Button from 'react-bootstrap/Button';


const data01 = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];
const data02 = [
  { name: 'A1', value: 100 },
  { name: 'A2', value: 300 },
  { name: 'B1', value: 100 },
  { name: 'B2', value: 80 },
  { name: 'B3', value: 40 },
  { name: 'B4', value: 30 },
  { name: 'B5', value: 50 },
  { name: 'C1', value: 100 },
  { name: 'C2', value: 200 },
  { name: 'D1', value: 150 },
  { name: 'D2', value: 50 },
];

const data03 = [
  { name: 'Goal 1', value: 82, current_value: 189000000 },
  { name: 'Goal 1', value: 18, current_value: 189000000 },
];

//https://www.npmjs.com/package/react-vertical-PieChart-component

function MyPieChart() {

    // load the complete state
    const { state } = useContext(AppContext);
    // with useState also main state gets
  
    const [ExplorerData, setExplorerData] = useState(state.ExplorerData);
    const [CoinGeckoData, setCoinGeckoData] = useState(state.CoinGeckoData);
    // Specific coin data
    const [coinData, setcoinData] = useState(state.CoinGeckoData.coin[0]);
  
    // connect visitor data to locale
    const [ VisitorFiatCurrency, setVisitorFiatCurrency]  =useState("USD".toLocaleLowerCase());
    const [ VisitorCryptoCurrency, setVisitorCryptoCurrency]  =useState("BTC".toLocaleLowerCase());
    
    // connect coingecko data to local variables
    const [CoinSymbol]    = useState(coinData["symbol"].toUpperCase());
    const [CurrentPriceArray]  = useState(coinData["market_data"]);
    const [CurrentFiatPrice]   = useState( CurrentPriceArray["current_price"][VisitorFiatCurrency]);
    const [CurrentCryptoPrice]   = useState( CurrentPriceArray["current_price"][VisitorCryptoCurrency]);
  
  
  
  

  const percentage = (value= 0) => {
    //`${((value / total) * 100).toFixed()}%`
    value = ((3000 / 17000) * 100).toFixed();

    return String(value) + "%";

  };

  const test = (value= 0) => {
    //`${((value / total) * 100).toFixed()}%`
    value = (360 - ((3000 / 17000) * 360)).toFixed();


    return value ;

  };

const total = (value= 0) => {
    return 17000 + 3000;
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    //const x = cx + radius * Math.cos(-midAngle * RADIAN);
    //const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const x = cx ;
    const y = cy ;
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {percentage()}
      </text>
    );
  };

    return (
      <div class="container text-center align-top">
          <PieChart width={400} height={400}>
            <Pie data={data03} dataKey="value" cx="50%" cy="50%" startAngle={90} endAngle={-270} innerRadius={110} labelLine={false} outerRadius={130} fill="#82ca9d" label={renderCustomizedLabel}>
            {data03.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}  
            </Pie>
          </PieChart>
      </div>
        
    );
  };
  

export default MyPieChart;