import logo from './logo.svg';
import React from 'react';
import ErrorBoundary from './ErrorBoundary';
import {BrowserRouter} from "react-router-dom";
//import AppRoutes from './AppRoutes';
import './CoinCSS.css';
import DataProvider from './Dataprovider';
import VisitorData from './VisitorData';
import Header from './Header';
import ApiExplorer from './ApiExplorer';
import ThemeProvider from 'react-bootstrap/ThemeProvider';

import Row1 from './Row1';
import SocialShare from './SocialShare';
import WordpressAPI from './WordpressApi';

import Test_CG from './Test_CG';


class App extends React.Component {
  constructor(props) {
      super(props);

      
    }

  render() {

    return (
      <div className="App" id="debug_frame" >
      <ErrorBoundary>
      <ThemeProvider
          breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
          minBreakpoint="xxs"
        >

        <BrowserRouter>
          //reserved for approutes
            <DataProvider>
              <Test_CG />
              <WordpressAPI />
              <VisitorData />
                  
                <div className="App">
                  <Header />
                  <ApiExplorer />
                  <Row1 />
                  <SocialShare />




         



              <header className="App-header">
                <h1>Version 16</h1>
                <div><small>You are running <b>{process.env.REACT_APP_NAME}</b> application for <b>{process.env.REACT_APP_COIN}</b> in <b>{process.env.NODE_ENV}</b> mode.</small></div>
                <div><small> eurocentiem-dev {this.context.error} </small></div>
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                  Edit <code>src/App.js</code> and save to reload.
                </p>
              
              </header> 


              

                </div>
                
              </DataProvider>
            //reserved for approutes
          </BrowserRouter>
          </ThemeProvider>    
      </ErrorBoundary>
      </div>

    );
  }
}

export default App;