
import React, {useState, useEffect,useReducer } from 'react';
import { default_addresstxs } from './explorer/default_addresstxs';
//Import immutability-helper
import update from "immutability-helper";
import { ExplorerDataContext } from '../ApiExplorer/context';
import { CoinGeckoDataContext } from '../Test_CG/context';
import { VisitorDataContext } from '../VisitorData/context';
import { useMountEffect } from '../Helpers';


var knex = require('knex')({
  client: 'sqlite3',
  connection: {
        filename: './test.db'
  },
});


export const ConnectionState = {
    DISCONNECTED: "disconnected",
    CONNECTING: "connecting",
    CONNECTED: "connected"
  };

  const console_int = {
    href : window.location.href,
    protocol : window.location.protocol,
    hostname : window.location.hostname,
    pathname : window.location.pathname,
  } 

  
const defaultState = {
    ip: '0.0.0.0',
    coin_address:'M and something',
    console:console_int,
    explorer_state_context: default_addresstxs,
    state: ConnectionState.DISCONNECTED,
    error: 'null',
    config: {},
    readings: [],
    lastReading: {},
    mode: "",
  };

  const DataContext = React.createContext({
    ...defaultState,
  });

  const ReadingsContext = React.createContext({
    readings: [],
    lastReading: {}
  });

  const xfactor =  window.location.pathname.replace("/","");

  function getCoinAddress(){
    if(window.location.pathname == "/"){
      return process.env.REACT_APP_COIN_DEFAULT_ADDRESS;
    } else {
      return window.location.pathname.replace("/","");
    } 
  };


  // Set up Initial State
const initialState = {
  inputText: "",
  milliseconds:0 ,
  reset_ms:0,
  stream1:0,
  stream2:0,
  CoinAdress:getCoinAddress(),
  AddressBalance:0.0,
  coin2usd:0.00002165,
  coin2btc:0.00000000078,
  btcvalue:28272,
  usd2eur:0.9,
  VisitorData:VisitorDataContext ,
  ExplorerData:ExplorerDataContext,
  CoinGeckoData:CoinGeckoDataContext,
  Console:console_int,
  Quote:'initialQuote'
};

function reducer(state, action) {
  switch (action.type) {
    case "UPDATE_INPUT":
      return update(state, { inputText: { $set: action.data } });
    case "UPDATE_MS":
        return update(state, { milliseconds: { $set: action.data } });
    case "UPDATE_BALANCE":
      return update(state, { AddressBalance: { $set: action.data } });
    case "UPDATE_STREAM1":
      return update(state, { stream1: { $set: action.data } });
    case "UPDATE_STREAM2":
      return update(state, { stream2: { $set: action.data } });
    case "UPDATE_LOG":
        return update(state, { testText: { $set: action.data } });
    case "UPDATE_QUOTE":
      return update(state, { quote: { $set: action.data } });
    case "UPDATE_VISITORDATA":
      return update(state, { VisitorData: { $set: action.data } });

    default:
      return initialState;
  }
}

export const AppContext = React.createContext();

function Datalayer(props) {

  const functionname ='Datalayer';

  const [state, dispatch] = useReducer(reducer, initialState);
  const [ CoinAdress, setCoinAdress] = useState(state.CoinAdress);


  const [milliseconds, setMilliSeconds] =  useState(0);
  const [simulation_stream_1,setSimulation_stream_1] = useState(0);
  const [simulation_stream_2,setSimulation_stream_2] = useState(0);
  const [totalbalance,settotalbalance] = useState(state.AddressBalance);

  useEffect(() => {
    const interval = setInterval(() => {
    
    setMilliSeconds(milliseconds => milliseconds + 1);
    state.milliseconds = state.milliseconds +1;
    }, 1);
    return () => clearInterval(interval);
  }, [ ]);
  
  useEffect(() => {
    setSimulation_stream_1(() => Math.sin(state.milliseconds/100));
    setSimulation_stream_2(() => Math.cos(state.milliseconds/100));

    dispatch({ type: "UPDATE_MS", data: milliseconds });
    dispatch({ type: "UPDATE_STREAM1", data: simulation_stream_1 });
    dispatch({ type: "UPDATE_STREAM2", data: simulation_stream_2 });
    dispatch({ type: "UPDATE_BALANCE", data: totalbalance });
    

  }, [milliseconds]); // <- add the milliseconds variable here

  const { children } = props;
  

  return (
    <div>
      <AppContext.Provider value={{ state, dispatch }}>
      { children }
        <div  className="debug_frame"><b>{ functionname }</b>       
            <div  className="debug_frame"> { milliseconds } </div>
            <div  className="debug_frame"> { window.location.href } </div>
            <div  className="debug_frame"> { window.location.protocol } </div>
            <div  className="debug_frame"> { window.location.hostname } </div>
            <div  className="debug_frame"> { window.location.pathname } </div>
            <div  className="debug_frame"> { milliseconds } </div>
            <div  className="debug_frame"> { totalbalance } cc</div>
        </div>
        </AppContext.Provider>
    </div>
    
  );


}; 

export default Datalayer;