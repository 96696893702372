//https://route360.dev/post/get-ip-react/
import React, {  useState, useContext } from 'react';
import { useMountEffect } from '../Helpers';
import '../CoinCSS.css'
import { AppContext } from "../Dataprovider";
import axios from "axios";

function VisitorData() {

    const { state } = useContext(AppContext)
    //const [apidata, setApiData] = useState({});
    const [VisitorData, setVisitorData] = useState(state.VisitorData);
    //let VisitorData = state.VisitorData;

    var API_URL = "https://ipapi.co/json/";

    // Only run once while after mounting
    useMountEffect(()=>{               
        fetchAPI();
    });

    //setting the state variable
    const fetchAPI = async () => {
        const response = await axios(API_URL);
       //console.log(response.data);
        //setApiData(response.data);
        setVisitorData(response.data)
       //console.log(apidata);
        };    



    return (
            <div class="container text-center">
                <div class="row align-items-start">
                    <div class="col col-sm order-3" >ip:{VisitorData.ip}</div>
                    <div class="col col-sm order-1" >network: {VisitorData.network}</div>
                    <div class="col col-sm order-2" >version: {VisitorData.version}</div>
                </div>
                <div class="row align-items-start">
                    <div class="col col-sm" >region: {VisitorData.region}</div>
                    <div class="col col-sm" >region_code: {VisitorData.region_code}</div>
                    <div class="col col-sm" >country: {VisitorData.country}</div>
                </div>
            </div>        
      );
    };

    export default VisitorData;