import React, { useRef, useState, useEffect, useContext } from 'react';
import { debugdiv, useMountEffect} from '../Helpers';
import '../CoinCSS.css';
import { AppContext } from "../Dataprovider";
import Button from 'react-bootstrap/Button';

import { Timeline, TimelineEvent } from "react-event-timeline";
import { IconName } from "react-icons/cg";
import { FaBeer } from "react-icons/fa";
import { FaArrowLeft,FaArrowRight } from 'react-icons/fa';



//https://www.npmjs.com/package/react-vertical-timeline-component

function TimeLine() {

  // load the complete state
  const { state } = useContext(AppContext);
  // with useState also main state gets

  const [ExplorerData, setExplorerData] = useState(state.ExplorerData);
  const [CoinGeckoData, setCoinGeckoData] = useState(state.CoinGeckoData);
  // Specific coin data
  const [coinData, setcoinData] = useState(state.CoinGeckoData.coin[0]);

  // connect visitor data to locale
  const [ VisitorFiatCurrency, setVisitorFiatCurrency]  =useState("USD".toLocaleLowerCase());
  const [ VisitorCryptoCurrency, setVisitorCryptoCurrency]  =useState("BTC".toLocaleLowerCase());
  
  // connect coingecko data to local variables
  const [CoinSymbol]    = useState(coinData["symbol"].toUpperCase());
  const [CurrentPriceArray]  = useState(coinData["market_data"]);
  const [CurrentFiatPrice]   = useState( CurrentPriceArray["current_price"][VisitorFiatCurrency]);
  const [CurrentCryptoPrice]   = useState( CurrentPriceArray["current_price"][VisitorCryptoCurrency]);





  const TestTimeLine = useContext(TimeLine);
  const TestArray = useState([]);
 





    useEffect(() => {
        //do something here
    }, []); // <- add a changing area here variable here like milliseconds

    const TimeStampToLocale = (timestamp) => {
      //const timestamp = Math.floor(Date.now()/1000);
      const locale="nl-be";
      
      const new_timestamp = new Date(timestamp * 1000);
     
  
      //const formatedDate = new Date(timestamp * 1000).toLocaleString({locale});
  
      //console.log(new_timestamp);
      //console.log(new_timestamp.toLocaleString("nl-be"));
      //console.log(locale);
      //onsole.log(new_timestamp.toLocaleString({locale}));
      return new_timestamp.toLocaleString("nl-be");
  
      //console.log(formatedDate);
  
    };

    const CreateTimeLine = ({}) => {
      let transaction={};
      for (transaction of ExplorerData.addresstxs.transactions){

        if (transaction.received > 0) {
          transaction.icon=<FaArrowRight />;
          transaction.timeline_title= transaction.balance + " " +  CoinSymbol + " ( " + Math.round(transaction.balance * CurrentFiatPrice)  + " " + VisitorFiatCurrency.toLocaleUpperCase() + " )";
          transaction.timeline_event_usd_value= transaction.balance * CurrentFiatPrice;
          transaction.timeline_event_usd_value= "Outgoing -"  + transaction.received +  " " + CoinSymbol +" ( -" +  Math.round(transaction.received * CurrentFiatPrice) + VisitorFiatCurrency.toLocaleUpperCase() + " )";
          transaction.timeline_event_usd_value= "Outgoing -"  + transaction.received +  " " + CoinSymbol +" ( -" +  Math.round(transaction.received * CurrentFiatPrice) + VisitorFiatCurrency.toLocaleUpperCase() + " )";

        } else {
          transaction.icon=<FaArrowLeft />;          
          transaction.timeline_title=transaction.balance;
          transaction.timeline_event_usd_value= "incoming +" + transaction.sent  + " " + CoinSymbol +" ( +" + Math.round(transaction.sent * CurrentFiatPrice) + VisitorFiatCurrency.toLocaleUpperCase() + " )";
        }
        

        let event_value = 0;
        let event_direction='sent';
        var event_icon = 0;

        if(transaction.received > 0){
          event_value = transaction.timestamp;

        } ;
        event_value = transaction.timestamp;
        
        TestArray.push(
          <TimelineEvent
          key={transaction.timestamp}
          title= { transaction.timeline_title }  
          icon= { transaction.icon }
          createdAt={TimeStampToLocale(transaction.timestamp)}>{transaction.timeline_event_usd_value} </TimelineEvent>
        )
      }; 

      return (TestArray );

      for (transaction of ExplorerData.addresstxs.transactions){
        return (
       






        <TimelineEvent
        title="Current Value"
        createdAt="2016-09-12 10:06 PM"
        icon={<i className="{IconName}  CgAddR">textsms</i>}
      >
        from create timeline   {TimeStampToLocale(transaction.timestamp)} 
      </TimelineEvent>

       );

      } 

    };

  
    return (  
      <div class="container text-center">
        <div class="row">
            <div class="col col-sm">
              <Timeline>
                <CreateTimeLine />
              </Timeline>
            </div>
            <div class="col col-sm"><div class="row"><Button variant="primary" size="lg" onClick={CreateTimeLine}>Create Timeline</Button></div></div>
        </div>
      </div>

            
        
    );
  };
  

export default TimeLine;