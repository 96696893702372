import React, { useState, useEffect } from 'react';
import { coingecko_state_context } from '../api_cg/context'; 
import { explorer_state_context } from '../ApiExplorer/context'; 

export const DeviceMode = {
  MANUAL: "manual",
  AUTOMATIC: "automatic"
};

export const ConnectionState = {
  DISCONNECTED: "disconnected",
  CONNECTING: "connecting",
  CONNECTED: "connected"
};

export const debugdiv = {
  border: '1px solid red', 
  padding: '5px',
};

export function trimTrailingChars(s, charToTrim) {
  var regExp = new RegExp(charToTrim + "+$");
  var result = s.replace(regExp, "");

  return result;
}

const console_int = {
  href : window.location.href,
  protocol : window.location.protocol,
  hostname : window.location.hostname,
  pathname : window.location.pathname,
  troep :'troep'
} 

export const app_state_context = React.createContext({
  ip:null,
  coin_address:console_int.pathname.split('/'),
  console:console_int,
  coingecko_state_context:coingecko_state_context,
  explorer_state_context:explorer_state_context
});

//temporary export
export const defaultState = {
  ip: null,
  state: ConnectionState.DISCONNECTED,
  error: null,
  config: {},
  readings: [],
  lastReading: {},
  mode: "",
  modeDisplay: "",
  info: {
    deviceName: "",
    firmwareVersion: "",
    hardwareVersion: "",
    deviceSerial: ""
  },
  status: {
    wifi: {},
    sd: {}
  },
  _ws_log: [],
  _serial_cb: () => {}
};

const ReadingsContext = React.createContext({
  readings: [],
  lastReading: {}
});

const HelpersDeviceContext = React.createContext('dummy contex');

//https://react-hookz.github.io/web/?path=/docs/lifecycle-usemounteffect--example
export  const useMountEffect = (fun) => useEffect(fun, []);

//https://bobbyhadz.com/blog/react-generate-random-number

export const randomNumberInRange = (min, max) => {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
} 

export const DeviceContext = React.createContext({
  ...defaultState,
  connect: (ip) => {},
  send: (data) => {},
  onSerialCmd: (fn) => {},
  dir: (path, cb) => {},
});



function Helpers(props) {



    const functionname ='Helpers';
    const [counter1, setCounter1] = useState(0);
    const [counter2, setCounter2] = useState(0);
    

    const [milliseconds, setMilliSeconds] = useState(0);
    const [simulation_stream_1,setSimulation_stream_1] = useState(0);
    const [simulation_stream_2,setSimulation_stream_2] = useState(0);


    useEffect(() => {
      const interval = setInterval(() => {
        setMilliSeconds(milliseconds => milliseconds + 1);
      //  setIsSimulation_stream_1(Math.sin(milliseconds));
      //  setIsSimulation_stream_2(Math.cos(milliseconds));
      }, 1);
      return () => clearInterval(interval);
    }, []);

    useEffect(() => {
      setSimulation_stream_1(() => Math.sin(milliseconds/100));
      setSimulation_stream_2(() => Math.cos(milliseconds/100));
      //time = milliseconds;
      //stream1 = milliseconds;
      //stream2 = milliseconds;
      
      //console.log(milliseconds);
    }, [milliseconds]); // <- add the milliseconds variable here

    const { children } = props;

    return(
      
        <HelpersDeviceContext.Provider value={{ simulation_stream_1, simulation_stream_2, counter1, setCounter1, counter2, setCounter2, milliseconds, setMilliSeconds, readings: [], lastReading: {}}}>
          { children }
          <div style={debugdiv}><b>{ functionname }</b></div> 
        </HelpersDeviceContext.Provider>
    )
}

export default Helpers;
export { ReadingsContext };