import React from 'react';

const wpaxios_response = {
    status: 0,
    statusText:"idle",
}; 

export const wpapi_context = React.createContext({
    state:'0',
    API_URL : 'IDLE',
    response : wpaxios_response,
  });

export default wpapi_context;