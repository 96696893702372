const VisitorDataJSON={
    "ip": "2a02:1810:4f0f:7c00:c9f4:8874:e73d:d0d5",
    "network": "2a02:1810:4f00::/42",
    "version": "IPv6",
    "city": "Kontich",
    "region": "Flanders",
    "region_code": "VLG",
    "country": "BE",
    "country_name": "Belgium",
    "country_code": "BE",
    "country_code_iso3": "BEL",
    "country_capital": "Brussels",
    "country_tld": ".be",
    "continent_code": "EU",
    "in_eu": true,
    "postal": "2550",
    "latitude": 51.1357,
    "longitude": 4.4449,
    "timezone": "Europe/Brussels",
    "utc_offset": "+0200",
    "country_calling_code": "+32",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": "nl-BE,fr-BE,de-BE",
    "country_area": 30510,
    "country_population": 11422068,
    "asn": "AS6848",
    "org": "Telenet BVBA"
}

export const VisitorDataContext={
    ip: "",
    network: "2a02:1810:4f00::/42",
    version: "IPv6",
    city: "Kontich",
    region: "Flanders",
    region_code: "VLG",
    country: "BE",
    country_name: "Belgium",
    country_code: "BE",
    country_code_iso3: "BEL",
    country_capital: "Brussels",
    country_tld: ".be",
    continent_code: "EU",
    in_eu: true,
    postal: 2550,
    latitude: 51.1357,
    longitude: 4.4449,
    timezone: "Europe/Brussels",
    utc_offset: "+0200",
    country_calling_code: "+32",
    currency: "EUR",
    currency_name: "Euro",
    languages: "nl-BE,fr-BE,de-BE",
    country_area: 30510,
    country_population: 11422068,
    asn: "AS6848",
    org: "Telenet BVBA"
}